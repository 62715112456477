body {
  /*overflow: hidden;*/
}

.main-wizard {
  width: 100%;
  height: 100vh;
  border: none;
}

#downloadAnchorElem {
  display: none;
}

.MuiExpansionPanelSummary-root {
  background-color: #f5f0ed !important;
}

.MuiExpansionPanelDetails-root {
  background-color: #f5f0ed !important;
}

.Settings {
  width: 400px;
  position: absolute;
  overflow-y: auto;
  left: 20px;
  bottom: 60px;
  background-color: #f5f0ed;
}

.Settings-container {
  width: 100%;
  max-height: 600px;
}

.BaseSettings,
.ProjectPageSettings,
.OutputMessages,
.Localization,
.Config {
  width: 100%;
}

.BaseSettings-SettingsBlock {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 20px;
}

.BaseSettings-TextField + .BaseSettings-TextField {
  margin-top: 10px;
}

.BaseSettings-Btn,
.Localization-Btn,
.Config-Btn_Save
 {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.Config-Btn {
  display: flex;
  justify-content: center;
}

.main-Panel {
  position: absolute;
  bottom: 10px;
  width: 400px;
  display: flex;
  left: 20px;
  justify-content: space-evenly;
}

.WhitelabelSettings-SettingsBlock,
.ProjectPageSettings-SettingsBlock,
.BaseSettings-TextField {
  display: flex;
  flex-direction: column;
}

.WhitelabelSettings-SettingsBlock + .WhitelabelSettings-SettingsBlock
{
  margin-top: 10px;
}

.OutputMessages,
.Localization {
  display: flex;
  flex-direction: column;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #90a4ae;
}
.token.punctuation {
  color: #9e9e9e;
}
.namespace {
  opacity: 0.7;
}

.token.property {
  color: #e91e63;
}
.token.string,
.token.char {
  color: #4caf50;
}

